import React from "react"
import SEO from "../components/seo"

import HeroSection from "../sections/home/hero"
import HeroSectionNew from "../sections/home/hero/HeroSectionNew"
import { Box } from "@chakra-ui/react"

const Home: React.FunctionComponent = () => {
  return (
    <Box minHeight="100vh">
      <Box position="relative">
        <SEO />
        <HeroSectionNew />
      </Box>
    </Box>
  )
}

export default Home
