import { Box, Flex, Image, Link as Link, Stack, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

interface Props {}

const HeroSectionNew: React.FunctionComponent<Props> = () => {
  return (
    <Box position="relative">
      <Box backgroundColor="white" color="black" display="block">
        <Flex flexDirection={["column-reverse", "column-reverse", "row"]}>
          <Flex flex={["0 1 100%", "0 1 100%", "0 1 50%", "0 1 50%"]}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Flex
                fontSize="lg"
                p="1em"
                minHeight="100vh"
                justifyContent="space-between"
                direction="column"
              >
                <Stack spacing={6}>
                  <Text>
                    👋 Hey, I'm Oscar{" "}
                    {`Watson-Smith, a Sales Engineer currently working at `}
                    <Link
                      textDecoration="underline"
                      textDecorationColor="purple"
                      target="_blank"
                      href="https://www.vanta.com/"
                      _hover={{
                        backgroundColor: "purple",
                        color: "white",
                      }}
                    >
                      {`Vanta`}
                    </Link>
                    . I've spent the better half of a decade working at the
                    nexus between product, engineering and sales to build
                    brilliant software at companies such as{" "}
                    <Link
                      href="https://datadoghq.com/"
                      textDecoration="underline"
                      textDecorationColor="blue"
                      target="_blank"
                      _hover={{
                        backgroundColor: "blue",
                        color: "white",
                      }}
                    >
                      Datadog
                    </Link>
                    {", "}
                    <Link
                      href="https://brighte.com.au"
                      textDecoration="underline"
                      textDecorationColor="green"
                      target="_blank"
                      _hover={{
                        backgroundColor: "green",
                        color: "white",
                      }}
                    >
                      Brighte
                    </Link>
                    {" and "}
                    <Link
                      href="https://ra.co/"
                      textDecoration="underline"
                      textDecorationColor="pink"
                      target="_blank"
                      _hover={{
                        backgroundColor: "pink",
                        color: "white",
                      }}
                    >
                      Resident Advisor
                    </Link>
                    .
                  </Text>
                  <Text>
                    I love building products and communities. I combined these
                    passions to create{" "}
                    <Link
                      href="https://soundcloud.com/nomadradiofm"
                      textDecoration="underline"
                      textDecorationColor="orange"
                      target="_blank"
                      _hover={{
                        backgroundColor: "orange",
                        color: "white",
                      }}
                    >
                      Nomad Radio
                    </Link>
                    {` `}— An independent, not-for-profit internet radio station
                    in Sydney. Aiming to solve problems I encountered when
                    running Nomad Radio for other stations, I've also built{" "}
                    <Link
                      href="https://usetonearm.com"
                      textDecoration="underline"
                      textDecorationColor="red"
                      target="_blank"
                      _hover={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                    >
                      Tonearm
                    </Link>
                    , an internet radio monitoring platform.
                  </Text>
                  <Text>
                    I also enjoy writing when I get the chance. Here's the
                    latest:
                  </Text>
                  <Stack>
                    <Box display="inline-block">
                      <Link>
                        <GatsbyLink to="/why-i-gave-up-drinking-in-my-twenties">
                          Why I gave up drinking in my early twenties
                          {" ↗"}
                        </GatsbyLink>
                      </Link>
                    </Box>
                    <Box display="inline-block">
                      <Link>
                        <GatsbyLink to="/building-an-internet-radio-station">
                          The tech behind building an internet radio station
                        </GatsbyLink>
                        {" ↗"}
                      </Link>
                    </Box>
                    <Box display="inline-block">
                      <Link>
                        <GatsbyLink to="/filezilla-causing-outages-drag-and-drop">
                          The carnage a single drag and drop can cause
                        </GatsbyLink>
                        {" ↗"}
                      </Link>
                    </Box>
                  </Stack>

                  <Text>
                    {`Here's some more `}
                    <Link
                      textDecoration="underline"
                      _hover={{
                        textDecorationColor: "black",
                        backgroundColor: "black",
                        color: "white",
                      }}
                    >
                      <GatsbyLink to="/writing">writing & notes</GatsbyLink>
                    </Link>
                    .
                  </Text>
                </Stack>
                <Stack paddingTop={[6, 12, 12]}>
                  <Text display="inline-block" as="span">
                    <Link
                      href="https://linkedin.com/in/oscargws"
                      target={"_blank"}
                    >
                      <Text as="span">Linkedin</Text>
                    </Link>
                    {" ↗"}
                  </Text>
                  <Text display="inline-block" as="span">
                    <Link href="https://github.com/oscargws" target={"_blank"}>
                      <Text as="span">Github</Text>
                    </Link>
                    {" ↗"}
                  </Text>
                </Stack>
              </Flex>
            </Box>
          </Flex>
          <Flex
            flex={["0 1 100%", "0 1 100%", "0 1 50%", "0 1 50%"]}
            position={["initial", "initial", "sticky"]}
            top={0}
            height={["50vh", "50vh", "100vh"]}
          >
            <Box
              paddingTop={["50vh", "50vh", "100vh"]}
              display="block"
              position="relative"
              width="100%"
            >
              <Image
                src={"/img/oscar.jpg"}
                position="absolute"
                objectPosition="top"
                height="100%"
                width="100%"
                inset={0}
                objectFit="cover"
                color="transparent"
              ></Image>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default HeroSectionNew
